* {
  box-sizing: border-box;
}

.slider {
  width: 100%;
  text-align: left;
  overflow: scroll;
}

.slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.slides::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.slides>div {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 100%;
  margin-right: 50px;
  border-radius: 10px;
  background: #eee;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.2s;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0px;
  /* border: 1px solid; */
}

@media screen and (max-width: 1140px) and (min-width: 900px) {
  .slides>div {
    width: 75% !important;
  }


}

@media screen and (max-width:900px) {
  .slides>div {
    width: 100% !important;
  }

}


.DisabledDiv {
  /* pointer-events: none; */
  display: none !important;
  opacity: 0.3;
}

.slider>a {
  display: inline-flex;
  width: 10rem;
  height: 2.5rem;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 0 16px 0.5rem 0;
  position: relative;
  gap: 10px;
  font-family: 'gb Regular';
  font-weight: 600;
  color: whitesmoke;
}

@media (max-width: 550px) {
  .slider>a {
    width: 5rem !important;
  }
}

.FileType {
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 18px;
  font-family: 'gb Regular';
}

.DownloadBlock {
  background: white;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 4px;
}



.label {
  width: 100%;
  height: 125px;
  padding: 15px;
  /* border-bottom: 1px solid #1e78a0; */
  border-radius: 5px;
  color: #1e78a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background-color: #9cd2e91c;
}

.label:hover {
  background-color: #ebf9ff;
}

.span {
  max-width: 100%;
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.FormContainer {
  gap: 15px;
  width: 40%;
  padding: 10px;
  display: grid;
  align-content: baseline;
  align-items: center;
  text-align: start;
}

@media screen and (max-width:1500px) and (min-width:800px) {
  .FormContainer {
    gap: 15px;
    width: 55% !important;
  }
}

@media (max-width:800px) {
  .FormContainer {
    gap: 15px;
    width: 100% !important;
  }
}



.FormContainerTwo {
  gap: 15px;
  width: 100% !important;
  display: grid;
  align-items: start;
  justify-content: start;

}


.innerContainer {}


.wrapper {
  margin: auto;
  padding-top: 0px;
  text-align: center;
}

.containerOne {
  background-color: #f9f9f9;
  padding: 0px;
  border-radius: 10px;
}


.upload-container {
  background-color: rgb(251 251 251);
  border-radius: 6px;
  padding: 10px;
}

.border-container {
  /* border: 5px dashed rgb(0 135 192); */
  border-radius: 10px;
  padding: 20px;
}

.border-container p {
  color: #130f40;
  font-weight: 600;
  font-size: 1.1em;
  letter-spacing: -1px;
  margin-top: 30px;
  margin-bottom: 0;
  opacity: 0.65;
}

.file-browser {
  text-decoration: none;
  color: #0087c0;
  /* border-bottom: 3px dotted rgba(22, 22, 255, 0.85); */
}

.file-browser:hover {
  color: #0087c0;
  border-bottom: 1px solid #0087c0;
  /* padding: 5px;
    margin:5px; */
  /* border-bottom: 3px dotted rgba(0, 0, 255, 0.85); */
}

.icons {
  color: #0087c0;
  opacity: 0.75;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 90px;
}

.FileName {
  font-size: 15px;
  color: #00b1fc;
  font-weight: bolder;
}

input[type="file"] {
  display: none;
}


.FormBlock {
  margin: 15px 15px 15px 25px;
  padding: 15px;
  background: #fbfcfb;
  border-radius: 1px;
  height: 70vh;
}

.UploadBtn {
  margin: 25px 10px;
  background: #5aaddc;
  padding: 10px 45px;
  border-radius: 5px;
  color: white;
  width: 9rem;
  transition: .2s all;
}

.UploadBtn:hover {
  box-shadow: 1px 1px 11px 1px #c39c1d3a;
  -webkit-transform: translate(-1px, -1px);
}

.UploadBtn:after {
  background-color: red !important;
}

.BodyContainer {
  padding: 10px;
}

@media screen and (max-width:600px) {
  .BodyContainer {
    padding: 0px !important;
  }
}

.LoadingScreen {
  height: 70vh;
  display: block;
  font-size: 30px;
  margin-top: 10px;
  padding: 40px;
}

.ReposneScreen {
  height: 65vh;
  display: block;
  font-size: 30px;
  margin-top: 10px;
  padding: 10px;
  overflow: scroll;
}

.ResponseInfoTitle {
  background: #eeefef;
  padding: 9px 10px;
  border-radius: 5px;
  font-size: 15px;
  color: green;
}

.ResponseInfoTitle a {
  cursor: pointer;
  color: #1c1e1e;
  text-decoration: blink;
  border-bottom: 1px solid;
}

.ResponseInfoDetailBox {
  background: #fafbfb;
  padding: 15px 0px;
  border-radius: 5px;
  font-size: 15px;
  color: #222622;
}

/* ol, ul, menu {
    list-style: square;
    margin: 15px;
    padding: 0;
}
ul li {
    padding: 15px 5px;
    border: 1px solid #2476983b;
} */

ul li b {
  cursor: pointer;
  color: #1c1e1e;
  text-decoration: blink;
  border-bottom: 1px solid;
}

.FlexContainerBox {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  line-height: 18px;
}

.FlexInnerContainerBox {
  display: grid;
  align-content: baseline;
  align-items: center;
  text-align: start;
  width: 12%;
  background-color: #b5ddee21;
  margin-top: 5px;
  font-size: 12px;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px;
  border: 1px solid #bebbbb2b;
}

@media screen and (max-width:1225px) and (min-width:640px) {
  .FlexInnerContainerBox {
    width: 30% !important;
  }

  .FlexContainerBox {
    gap: 5%;
  }
}

@media screen and (max-width:640px) {
  .FlexInnerContainerBox {
    width: 48% !important;
  }
}

.FlexInnerContainerBox>label {
  margin-bottom: 5px;
  font-weight: bolder;
  font-family: 'gb bold';
  color: #252a2c;
}

.FlexInnerContainerBox>hr {
  background-color: #45424285;
}

.FlexInnerContainerBox>div {
  cursor: pointer;
  margin: 2px 0px;
  color: #646565d4;
}

/* filter  */
.FilterContainer {
  display: flex;
  padding: 0px 0px;
  align-items: center;
  justify-content: flex-start;
  background: #fafbfb;
}

.FilterBtn {
  background: #e2e1e126;
  padding: 5px 25px;
  border-radius: 5px;
  color: #3e3b3b;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #221c1c40;
  margin: 1px 0px;
  font-size: 12px;
}

.FilterBtn:hover {
  background: #1cafe538 !important;
  padding: 5px 30px;
  border-radius: 5px;
  color: #000;
}

.ChipContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
}

.FilterChip {
  margin: 1px 10px;
  background: #e1e1e17d;
  padding: 5px;
  display: flex;
  gap: 10px;
  border-radius: 5px;
  white-space: pre;
}

.FilterBox {
  padding: 10px 25px;
  width: 90%;
  height: 47vh;
  margin: 10px 20px;
  overflow: scroll;
}

.FilterElm {
  display: grid;
  gap: 5px;
  margin: 20px 0px;
  font-size: 12px;
  font-family: "gb Regular";
}

.FilterElm label {
  color: rgb(35 32 32 / 76%);
}

.FilterCancelBtn {
  background: #b0b0b0;
  padding: 10px 30px;
  border-radius: 5px;
  color: white;
}

.FilterApplyBtn {
  background: rgb(44 175 229 / 52%);
  padding: 10px 10px;
  border-radius: 5px;
  color: rgb(2, 2, 2);
  width: 100%;
  margin: 15px;
}

/* active filters */
.afTitle {
  padding: 10px;
  font-size: 18px;
  font-weight: bolder;
  font-family: 'gb Regular';
  color: #7c7e7e;
}

/* assign record */
.arTitle {
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'gb Regular';
}

.arFilterBox {
  padding: 2px 2px;
  width: 100%;
  height: 47vh;
  margin: 2px 2px;
  overflow: scroll;
}

.arFilterElm {
  display: grid;
  gap: 5px;
  margin: 20px 0px;
  font-size: 12px;
  font-family: "gb Regular";
}

.arFilterElm label {
  color: rgb(35 32 32 / 76%);
}

/* btn */
.UserAllocateBtn {
  display: flex;
  align-items: center;
  background: #a2dbf1;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 10px;
}

.UserAllocateBtn:hover {
  display: flex;
  align-items: center;
  background: #a2dbf1;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 10px;
  box-shadow: 2px 3px 3px;
}

/* assign bar */
.assignBar {
  display: flex;
  gap: 10px;
  padding: 10px;
}

.assignBtn {
  display: flex;
  align-items: center;
  background: #a2dbf1;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 10px;
  width: 115px;
}

.assignBtn:hover {
  display: flex;
  align-items: center;
  background: #a2dbf1;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 10px;
  box-shadow: 2px 3px 3px;
  width: 115px;
}

.titleForAssign {
  padding: 5px 10px;
  margin: 5px 0px;
  font-size: 14px;
  font-weight: 900;
  background: #cdcdcd47;
  border-radius: 4px;
  color: #5c5c5cdb;
}

/* reset btn */

.resetBtn {
  display: flex;
  align-items: center;
  background: #dae0e2ab;
  color: #282222;
  padding: 8px 20px;
  font-size: 12px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 5px;
  width: 125px;
}

.resetBtn:hover {
  background: #cbcbcb;
  color: #0e0e0e;
}


/* elem */

.RespElements {
  padding-left: 10px;
  display: grid;
  align-content: baseline;
  align-items: center;
  text-align: start;
  width: 33.33%;
}


@media screen and (max-width: 1246px) and (min-width: 600px) {
  .RespElements {
    padding: 0px;
    margin: 5px;
    display: grid;
    align-content: baseline;
    align-items: center;
    text-align: start;
    width: 50% !important;
  }
}


@media screen and (max-width: 600px) {
  .RespElements {
    padding: 0px;
    margin: 5px;
    display: grid;
    align-content: baseline;
    align-items: center;
    text-align: start;
    width: 98% !important;
  }
}



.Overlay-effect {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.Overlay-effect>div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  color: white;
}

.overlay-inner {
  text-align: center;
}

.overlay-inner>p {
  text-align: center;
  margin: 5px;
  font-size: 16px;
  font-weight: 900;
  font-family: 'gb Bold';
}

.overlay-inner-center {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.dot-flashing {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
  color: white;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: white;
  }

  50%,
  100% {
    background-color: rgba(126, 183, 233, 0.2);
  }
}

/* date picker */
.dateRangeContainer {
  padding: 10px 20px;
}

.DateTitle {
  margin: 10px 0px;
}

.dateRangeBlock {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  line-height: 15px;
}

.dateItem {
  display: grid;
  gap: 0px;
  justify-content: center;
  align-items: center;
}

.dateItem>button {
  padding: 10px 20px;
  background: #1fafe5e8;
  border-radius: 8px;
  color: #ffffff;
  margin: 10px 10px 0px 0px;
}

.dateItem>button:hover {
  cursor: pointer;
  background: #1fb0e5c2;
}

.inputDate {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
  margin: 5px 0px;
}


/* download dispositions btn */

.download-dispositions-btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  background: rgb(96 119 247 / 60%);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px;
  color: #ffffff;
  font-size: .85rem;
}

.download-dispositions-btn:hover {
  cursor: pointer;
  background-color: rgb(0 39 255 / 60%);
}


/*  top nav bar css  */
.pa-top-bar {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 5px;
  overflow-x: auto;
  /* Allow horizontal scrolling */
  position: fixed;
  top: 0px;
  border-bottom: none;
  z-index: 999;
  margin: 0px 0px;
  height: 50px;
  background-color: #f2f7f3;
  width: 100%;
}

.pa-top-bar>button {
  padding: 5px 13px;
  border-radius: 5px;
  font-size: .83rem;
  white-space: pre;

  background: #fffffff2;
  color: rgb(0, 0, 0);
  border: 1px solid #e4e4e4a1;

}

.pa-top-bar>button:hover {
  cursor: pointer;
  border: 1.1px solid #e4e4e4a1 !important;
  background: #22aee59f !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 4px 0 rgba(223, 220, 220, 0.19) !important;
}

.toggle-selected-btn {
  background: #22afe5f2 !important;
  color: white !important;
  border: 1.1px solid #e4e4e4a1 !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 4px 0 rgba(0, 0, 0, 0.19) !important;
}



.pa-CustomLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  gap: 1rem;
  padding: 5px 13px;
  border: 0.1px solid #4c4c4ca1;
  border-radius: 5px;
  font-size: .83rem;
  white-space: pre;
  background: #fffffff2;
  color: rgb(0, 0, 0);
}

.pa-CustomLink:hover {
  background-color: rgb(236 246 249) !important;
  color: rgb(96 82 82) !important;
  transition: background-color 100ms ease-out;
}

/* block outlet */
.pa-main-outlet {
  /* border: 1px solid #e7dddd; */
  padding: 2px 5px;
  border-radius: 5px;
  margin-top: 40px;
  min-height: 85vh;
  height: auto;
}


.status-btn-class {}

.status-btn-class:hover {
  cursor: pointer !important;
}


/* campaign Button */

.create-campaign-with-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  background: #ff6347;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  margin: 5px 0px;
  color: #ffffff;
  font-size: .85rem;
}

.create-campaign-with-list:hover {
  cursor: pointer;
  background-color: #ff6347dd;
  color: #e3e1e1;
  border: .5px solid rgba(118, 116, 116, 0.48);
}


/* top head for details */

.basic-detail-top-block {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1%;
}

.basic-detail-top-block>hr {
  border-top-width: 1px;
  color: inherit;
  height: 0;
}

.bdtb-first {
  width: 49%;
  border: 1px solid #b9b9b985;
  border-radius: 5px;
  min-height: 25vh;
  overflow: scroll;
}

.bdtb-first-head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.bdtb-second {
  width: 50%;
  border: 1px solid #b9b9b985;
  border-radius: 5px;
  min-height: 25vh;
  overflow: scroll;
}


.bdtb-second-head {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

@media screen and (max-width:900px) {
  .basic-detail-top-block {
    display: block;
  }

  .bdtb-first {
    width: 100%;
    overflow: scroll;
    margin-bottom: 5px;
  }

  .bdtb-second {
    width: 100%;
    overflow: scroll;
  }
}
