@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@font-face {
    font-family: "gb Black";   /*Can be any text*/
    src: local("NotoSansKR"),
      url("./assets/fonts/NotoSansKR-Black.otf") format("opentype");
  }
  @font-face {
    font-family: "gb Bold";   /*Can be any text*/
    src: local("NotoSansKR"),
      url("./assets/fonts/NotoSansKR-Bold.otf") format("opentype");
  }
  @font-face {
    font-family: "gb Light";   /*Can be any text*/
    src: local("NotoSansKR"),
      url("./assets/fonts/NotoSansKR-Light.otf") format("opentype");
  }
  @font-face {
    font-family: "gb Medium";   /*Can be any text*/
    src: local("NotoSansKR"),
      url("./assets/fonts/NotoSansKR-Medium.otf") format("opentype");
  }
  @font-face {
    font-family: "gb Regular";   /*Can be any text*/
    src: local("NotoSansKR"),
      url("./assets/fonts/NotoSansKR-Regular.otf") format("opentype");
  }
  @font-face {
    font-family: "gb Thin";   /*Can be any text*/
    src: local("NotoSansKR"),
      url("./assets/fonts/NotoSansKR-Thin.otf") format("opentype");
  }
body{
    margin: 0;
    padding:0;
    font-family: "gb Regular";
    background: #f2f2f2;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    font-variant: normal;
    font-kerning: auto;
    font-stretch: 100%;
}

@tailwind base;
@tailwind components;
@tailwind utilities;