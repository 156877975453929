@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.sideButton {
    position: absolute;
    right: 0px;
    font-size: 25px;
    padding: 1px;
    top: 60px;
    background-color: transparent;
}

.sideButtonMobile {
    position: absolute;
    right: 0px;
    font-size: 25px;
    padding: 1px;
    top: 60px;
    background-color: transparent;
}

.sidebar-user-block {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    border-radius: 14px;
    padding: 10px 20px;
    align-items: center;
    text-align: center;
    margin: 0px 10px;
    flex-direction: row;
    background-color: #F6F6F6;
    color: #0A1629;
    margin-bottom: -25px;
}

.sidebar-user-block>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 10px;
}

.sidebar-user-block>div>span {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
}