@import url('https://cdn.syncfusion.com/ej2/material.css');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;900&display=swap'); */
:root {
  --loading-grey: #ededed;
}

.sidebar {
  box-shadow: rgba(0, 154, 36, 0.11) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
  z-index: 10000;
}

@media screen and (max-width:800px) {

  .sidebar {
    z-index: 10000000;
  }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
  z-index: 1000000 !important;
}

.css-wjh20t-MuiPagination-ul {
  display: flex;
  width: max-content;
}

.css-1oj2twp-MuiPagination-root {
  overflow: auto;
}


::-webkit-scrollbar {
  /* width: 6px; */
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 0px;
  /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
  /* background: #e2f7ff; */
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}


/* color-picker style  */

#preview {
  background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
  border: none;
}


/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, .8), rgba(0, 0, 0, .8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* navigation animation */
.side-bar {
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.4s ease-out;
}

.side-bar a:hover {
  transition: 0.4s ease;
}

.side-bar .close {
  position: absolute;
  top: 0;
  right: 22px;
  margin-left: 50px;
  font-size: 30px;
}


/* filter chips */

.tagsChip {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 0.5rem;
}

.tagChip {
  --color: var(--light-color);
  font-family: var(--regular-font);
  font-size: 1rem;
  border-radius: 100rem;
  box-shadow: 0 0 0 2px var(--light-color);
  background-color: transparent;
  color: var(--dark-color);
  font-weight: bold;
  transition:
    background-color 250ms ease,
    box-shadow 250ms ease;
}

.tagChip>a {
  display: inline-block;
  position: relative;
  color: var(--dark-color);
  text-decoration: none;
  padding: .5rem 1rem .5rem 2rem;
}

.tagChip>a::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 1rem;
  width: .5rem;
  height: .5rem;
  border-radius: 100%;
  background-color: var(--color);
  transform: translateY(-50%);
}

.tagChip:hover {
  background-color: var(--light-color);
  box-shadow: 0 0 0 calc(2px + .25rem) var(--light-color);
}

/* custom Modal width reponse */
.CustomModalWidth {
  width: 400px;
}

.filterAllocationDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  font-family: "gb Regular";
  font-weight: 800;
  padding: 10px;
  width: 100%;
}

@media screen and (max-width:440px) {
  .CustomModalWidth {
    width: 325px;
  }
}

.CuElements {
  padding: 10px;
  display: grid;
  align-content: baseline;
  align-items: center;
  text-align: start;
  width: 33.33%;
}

.CuElementsTwo {
  padding: 10px;
  display: grid;
  align-content: baseline;
  align-items: center;
  text-align: start;
  width: 90%;
}

@media screen and (max-width: 1246px) and (min-width: 600px) {
  .CuElements {
    padding: 10px;
    display: grid;
    align-content: baseline;
    align-items: center;
    text-align: start;
    width: 50% !important;
  }
}

@media screen and (max-width: 1140px) and (min-width: 600px) {
  .CuElementsTwo {
    padding: 10px;
    display: grid;
    align-content: baseline;
    align-items: center;
    text-align: start;
    width: 50% !important;
  }
}

@media screen and (max-width: 600px) {
  .CuElements {
    padding: 10px;
    display: grid;
    align-content: baseline;
    align-items: center;
    text-align: start;
    width: 98% !important;
  }

  .CuElementsTwo {
    padding: 10px;
    display: grid;
    align-content: baseline;
    align-items: center;
    text-align: start;
    width: 98% !important;
  }
}

/* custom button */
.customBtn {
  align-self: flex-end;
  width: max-content;
  padding: 6px 2em;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px #0002;
  font-size: 15px;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-left: 30px;
}

.customBtn:hover {
  box-shadow: 0 0 4px 2px #0003;
  transform: scale(1.1);
}

.clearBtn {
  background-color: #d3d7d3;
  color: #222222;
}

.submitBtn {
  background-color: #1cafe5;
  color: white;
}

.editBtn {
  background-color: #eec11d;
  color: white;
}


.MuiSnackbar-anchorOriginTopRight {
  top: 80px !important;
  z-index: auto !important;
  position: absolute !important;
}

/* details admin user */



.grid-container {
  margin: 10px 10px 10px 0px;
  width: 100%;
  height: 75vh;
  display: grid;
  grid-auto-columns: 1fr;
  gap: 1em;
  grid-template-areas:
    'one one one one two two'
    'one one one one two two'
    'one one one one two two'
}

.grid-item {
  border-radius: 1em;
  display: flow-root;
  color: black;
  padding: 15px 10px 10px 10px;
}

.grid-item:nth-child(1) {
  grid-area: one;
  background-color: #f6f6f6;
}

.grid-item:nth-child(2) {
  grid-area: two;
  background-color: #d9eef6;
}



@media (max-width: 1140px) {
  .grid-container {
    grid-template-areas:
      'one one one one one one'
      'one one one one one one'
      'two two two two two two'
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-areas:
      'one'
      'one'
      'one'
      'one'
      'two'
      'two'
  }
}

/* user edit btn */



.logoutBtn {
  /* display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 10px 20px;
  font-size: 11px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 8px;
  margin: 10px;
  gap: 6px; */
  display: inline-flex;
  width: 16px;
  height: 16px;
  font-size: 16px;
  vertical-align: middle;
  margin-right: 8px;
}

.logoutBtn:hover {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 8px;
  margin: 10px;
  gap: 6px;
  box-shadow: 0 0 4px 2px #0003;
  transform: scale(1);
}

/* alert */
.CustomAlert {
  display: flex;
  align-items: center;
  border: 1px solid;
  color: white;
  padding: 8px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 700;
  gap: 20px;
}

.errorClass {
  background: #E51F1F;
  color: #fff;
  padding: 10px 16px;
  border: 1px solid #E51F1F;
  border-radius: 10px
}

.successClass {
  background: #8dd46b;
  color: #000;
  padding: 10px 16px;
  border: 1px solid #ACF589;
  border-radius: 10px;
}

.defaultClass {
  background-color: #e7c077;
  color: #4e595e;
  padding: 10px 16px;
  border: 1px solid #e7c077;
  border-radius: 10px;
}



/* navbar */
.CustomNavBar {
  padding: 1px 5px 0px;
  background: transparent;
  height: auto;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

/* snakbar */
.AlertSnackbar {
  top: 100px !important;
}

/* details copy user id */
.CopyUserId {
  padding: 2px;
  border-radius: 3px;
}

.CopyUserId::after {
  box-shadow: inset 0 0 10px;
}

/* .CustomLinkActive{
}
.CustomLinkNormal:hover{
  background-color: red !important;
} */

/* .CustomLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  gap: 1rem;
  font-size: 15px;
}

.CustomLink:hover {
  background-color: rgba(131, 167, 220, 0.112) !important;
  color: rgb(96 82 82) !important;
  transition: background-color 10ms ease-out;
} */


/* not found */


.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {

  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
}


.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}


/* btn */
.UserEditBtn {
  display: flex;
  align-items: center;
  background: #a2dbf1;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 900;
  font-family: 'gb Regular';
  border-radius: 10px;
}

.UserEditBtn:hover {
  box-shadow: 1px 0px 0px 1px inset #72b5d1;
}

/* profile card */
.profile-main-container {
  border: 1px solid #1fafe5ff;
  position: fixed;
  left: 0;
  width: fit-content;
  bottom: 3rem;
  border-radius: 10px;
  background: #F7FBFE;
  margin: 10px 40px;
  right: 0;
  font-family: 'Nunito Sans', sans-serif;
  z-index: 999;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.profile-main-container:hover {
  transform: translateY(-15px);
  /* Moves the element up more on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  /* Increases shadow intensity on hover */
}


.profile-inner-top {
  display: flex;
  padding: 10px 10px 10px 10px;
  justify-content: flex-end;
}

.profile-mid-container {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F7FBFE;
}

.p-p1 {
  font-size: 14px;
  font-weight: bolder;
  font-family: 'gb Bold';
}

.p-p2 {
  font-size: 11px;
}

.p-p3 {
  font-size: 11px;
}

.profile-logout-btn {
  display: flex;
  padding: 5px 20px 5px 20px;
  justify-content: space-between;
  margin: 0px 10px;
  background: #408cff;
  border-radius: 10px;
  align-items: center;
  gap: 7px;
  color: white;
  font-size: 12px;
}

.profile-logout-btn:hover {
  background: #408cffa6;
  color: white;
}

.profile-bottom-container {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0px;
}

/* offlien screen */

.offline-screen {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 90%;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.offline-no-internet {
  background-color: red;
  width: 8rem;
  padding: 10px;
  border-radius: 3px;
  color: white;
  font-size: 13px;
}

.offline-desc {
  padding: 10px 10px 10px 0px;
  border-radius: 4px;
  color: #4b4040;
  font-size: 12px;
}


/* restrcited path */
.restrcited-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.restrcited-page-inner {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.restrcited-page-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.restrcited-page-p {
  font-size: 12px;
  font-weight: 800;
  font-family: 'gb Regular';
  color: #8d7373f5;
}

.journeyBtn {
  display: flex;
  justify-content: center;
  font-size: 25px;
}

.CustomModalWidthUserJourney {
  width: 90%;
}

.journeyHead {
  width: 100%;
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-size: 15px;
  font-weight: 800;
  border-radius: 5px;
  border-bottom: 0.7px solid #8080807a;
  color: #2a7da0;
}

.j-Head {
  font-size: 18px;
  font-weight: bolder;
}

.journeyBody {}

.journeyBody-item {
  display: flex;
  gap: 20px;
  padding: 20px 30px;
}

.item-number {
  background-color: red;
}

.item-action {
  background-color: pink;
}

.item-date {
  background-color: yellow;
}



/* djkdl */

/* deleteCampaign */
.deleteCampaign {
  /* border: 1px solid #f0f0f0;
  border-radius: 8px;
  white-space: pre;
  padding: 2px 5px;
  background: #ec2323e6;
  color: white; */
}

.deleteCampaign:hover {
  cursor: pointer;
}


/* record not found css */
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  /* Optionally, if you want the container to take the full height of the viewport */
}

.centered-item {
  text-align: center;
  /* Optionally, for centering text within the item */
}

.centered-item>p>div {
  font-weight: bolder;
  font-size: 1.6em;
  font-family: sans-serif;
  line-height: 0px;
}

.centered-item>p>span {
  font-weight: 500;
  font-size: 1em;
  font-family: 'gb Regular';
}

.centered-item>p>span>b {
  color: #506953;
  font-weight: bolder;
  font-family: fangsong;
}


/* view details of tc for tl dashboard btn*/
.tl-tc-view-btn {
  background: #f7f7f7;
  padding: 1px 20px;
  border: 1px solid #8080803d;
  border-radius: 5px;
  color: #544e4e;
}

.tl-tc-view-btn:hover {
  cursor: pointer;
  background: #e9e9e9;
  padding: 1px 20px;
  border: 1px solid #0c0c0c3d;
  border-radius: 5px;
  color: #544e4e;
}


/* base color for theming */

.tdf-base {
  color: #22afe5;
  /* Base color */
}

.tdf-darker {
  color: #1c99c6;
  /* Darker shade */
}

.tdf-darker2 {
  color: #187fa5;
  /* Even darker shade */
}

.tdf-lighter {
  color: #4dc7f2;
  /* Lighter shade */
}

.tdf-lighter2 {
  color: #80d8ff;
  /* Even lighter shade */
}

.tdf-accent1 {
  color: #ff6347;
  /* Accent color 1 (Coral) */
}

.tdf-accent2 {
  color: #ffc0cb;
  /* Accent color 2 (Pink) */
}

.tdf-primaryButton {
  background-color: #22afe5;
  /* Primary button color */
  color: #ffffff;
  /* Text color for primary button */
}

.tdf-primaryButton:hover {
  background-color: #1c99c6;
  /* Hover state for primary button */
}

.tdf-secondaryButton {
  background-color: #ffffff;
  /* Secondary button color */
  color: #333333;
  /* Text color for secondary button */
}

.tdf-secondaryButton:hover {
  background-color: #f2f2f2;
  /* Hover state for secondary button */
}

.tdf-downloadButton {
  background-color: #4dc7f2;
  /* Download button color */
}

.tdf-downloadButton:hover {
  background-color: #1c99c6;
  /* Hover state for download button */
}

.tdf-popupBackground {
  background-color: #ffffff;
  /* Background color for pop-ups */
  color: #333333;
  /* Text color for pop-ups */
}

.tdf-popupButton {
  background-color: #22afe5;
  /* Button color in pop-ups */
  color: #ffffff;
  /* Text color for buttons in pop-ups */
}

.tdf-popupButton:hover {
  background-color: #1c99c6;
  /* Hover state for buttons in pop-ups */
}

.tdf-sideMenuBackground {
  background-color: #333333;
  /* Background color for side menu */
  color: #ffffff;
  /* Text color for side menu */
}

.tdf-sideMenuHover {
  background-color: #4dc7f2;
  /* Hover state for side menu items */
}

.tdf-sideMenuActiveBackground {
  background-color: #1c99c6;
  /* Background color for active side menu item */
}


.tdf-lighter3 {
  color: #b3e0ff;
  /* Even lighter shade */
}

.tdf-accent3 {
  color: #ffa07a;
  /* Accent color 3 (Light Salmon) */
}

.tdf-accent4 {
  color: #dda0dd;
  /* Accent color 4 (Plum) */
}

.tdf-accent5 {
  color: #00fa9a;
  /* Accent color 5 (Medium Spring Green) */
}

.tdf-primaryButtonOutline {
  border: 2px solid #22afe5;
  /* Border color for outline primary button */
  color: #22afe5;
  /* Text color for outline primary button */
}

.tdf-primaryButtonOutline:hover {
  background-color: #22afe5;
  /* Background color on hover for outline primary button */
  color: #ffffff;
  /* Text color on hover for outline primary button */
}

.tdf-secondaryButtonOutline {
  border: 2px solid #ffffff;
  /* Border color for outline secondary button */
  color: #ffffff;
  /* Text color for outline secondary button */
}

.tdf-secondaryButtonOutline:hover {
  background-color: #ffffff;
  /* Background color on hover for outline secondary button */
  color: #333333;
  /* Text color on hover for outline secondary button */
}

.tdf-downloadButtonOutline {
  border: 2px solid #4dc7f2;
  /* Border color for outline download button */
  color: #4dc7f2;
  /* Text color for outline download button */
}

.tdf-downloadButtonOutline:hover {
  background-color: #4dc7f2;
  /* Background color on hover for outline download button */
  color: #ffffff;
  /* Text color on hover for outline download button */
}

.tdf-popupButtonOutline {
  border: 2px solid #22afe5;
  /* Border color for outline buttons in pop-ups */
  color: #22afe5;
  /* Text color for outline buttons in pop-ups */
}

.tdf-popupButtonOutline:hover {
  background-color: #22afe5;
  /* Background color on hover for outline buttons in pop-ups */
  color: #ffffff;
  /* Text color on hover for outline buttons in pop-ups */
}


/*  global loading css */
.tdf-loading {
  background-color: var(--loading-grey);
  background: linear-gradient(100deg,
      rgba(255, 235, 255, 0) 40%,
      #a0dbf224 50%,
      rgba(255, 235, 255, 0) 60%) var(--loading-grey);
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: .7s loadingtdf ease-in-out infinite;
}

@keyframes loadingtdf {
  to {
    background-position-x: -20%;
  }
}

.tdf-l-card-h {
  min-height: 4.8rem;
  border-radius: 4px;
  animation-delay: .05s;

  background-color: rgb(243 243 243 / 43%);
  width: 10rem;
  border-radius: 5px;
  box-shadow: 1px 1px 8px #dddddd;
  margin: 5px 5px;
  padding: 2px 50px 6px 14px;
  border-top: 2px solid #1daee5;
  min-width: 140px;
}

.tdf-l-card-d {
  min-height: 4rem;
  border-radius: 4px;
  animation-delay: .06s;
}


/* back btn */

.back-screen-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 10px;
  background: #92dafc4a;
  border-radius: 5px;
  color: #756c6c;
}




/* block box responsive box createDetailSummaryBlock */

.ctsb-box {
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fit, 24%);
  margin: 10px 2px;
}

.ctsb-box-item {
  background-color: rgb(240 233 233 / 49%);
  border-radius: 8px;
  margin: 2px;
  width: 100%;
  color: white;
  display: grid;
  justify-content: start;
  align-items: center;
  padding: 8px 15px;

  background-color: #b5ddee21;
  margin-top: 5px;
  font-size: 12px;
  border-radius: 0px 10px 10px 10px;
  padding: 5px;
  border: 1px solid #bebbbb2b;
}

.ctsb-box-item>span {
  font-size: 1.05em;
  color: rgb(54 54 54 / 73%);
  font-weight: bolder;
  font-family: 'gb Bold';
}

.ctsb-box-item>p {
  font-size: 1.3em;
  color: rgb(85, 85, 85);
  overflow: hidden;
}

@media screen and (max-width: 1200px) and (min-width: 750px) {
  .ctsb-box {
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fit, 33%);
    margin: 10px 2px;
  }
}

@media (max-width: 750px) {
  .ctsb-box {
    justify-content: space-between;
    display: grid;
    grid-template-columns: repeat(auto-fit, 48%);
    margin: 10px 2px;
  }
}


/* custom alert message */

/* Alert.css */
.alert {
  position: fixed;
  right: 0px;
  min-width: 380px;
}

.alert-message {
  flex: 1;
}

.alert-close {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: 10px;
}

/* sidebar */

.sidebar-title {
  color: black;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
}

.sidebar-links {
  border-left: 5px solid #efefef;
  margin-left: 0;
}

.CustomLink-active {
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  background: rgba(34, 175, 229, 0.95);

  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  gap: 10px;
}

.CustomLink-inactive {
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  gap: 10px;
}

/* .CustomLink {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 500;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;

  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  gap: 10px;
} */

.CustomLink-active:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 2px;
  height: 100%;
  border-radius: 10px;
  background-color: #32b4e6;
}




.CustomLink-inactive:hover {
  /* background-color: #e3f2fd; */
  background: #d4e6f9;
  /* Slightly darker blue on hover */
  box-shadow: 0 2px 10px rgba(135, 47, 47, 0.1);
  /* Adds a shadow effect on hover */
  cursor: pointer;
  /* Changes cursor to pointer on hover */


}

.CustomLink-inactive:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -10px;
  width: 2px;
  height: 100%;
  border-radius: 10px;
  background-color: #32b3e621;
}

.CustomLink-active:hover {
  /* background-color: #e3f2fd; */
  background: #d4e6f9;
  /* Slightly darker blue on hover */
  box-shadow: 0 2px 10px rgba(135, 47, 47, 0.1);
  /* Adds a shadow effect on hover */
  cursor: pointer;
  /* Changes cursor to pointer on hover */
}

.CustomLink span {
  /* margin-left: 0.5rem; */
}

.sidebar-open-btn {
  height: 93vh;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: rgb(62 140 255 / 17%);
  color: black;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  /* Adds smooth transitions */
}

.sidebar-open-btn:hover {
  background: #d4e6f9;
  /* Slightly darker blue on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  /* Adds a shadow effect on hover */
  cursor: pointer;
  /* Changes cursor to pointer on hover */
}



/* gradient list */

/* Gradient 1: Blue to Purple */
.gradient-blue-purple {
  background: linear-gradient(45deg, #3F8CFF, #8000FF);
}

/* Gradient 2: Purple to Pink */
.gradient-purple-pink {
  background: linear-gradient(45deg, #8000FF, #DE92EB);
}

/* Gradient 3: Pink to Yellow */
.gradient-pink-yellow {
  background: linear-gradient(45deg, #DE92EB, #FFBD21);
}

/* Gradient 4: Yellow to Green */
.gradient-yellow-green {
  background: linear-gradient(45deg, #FFBD21, #19CC52);
}

/* Gradient 5: Green to Blue */
.gradient-green-blue {
  background: linear-gradient(45deg, #19CC52, #85B6FE);
}

/* Gradient 6: Blue to Pink */
.gradient-blue-pink {
  background: linear-gradient(45deg, #3F8CFF, #DE92EB);
}

/* Gradient 7: Pink to Purple */
.gradient-pink-purple {
  background: linear-gradient(45deg, #DE92EB, #8000FF);
}

/* Gradient 8: Multi-color Gradient */
.gradient-multi {
  background: linear-gradient(45deg, #3F8CFF, #DE92EB, #FFBD21, #19CC52, #85B6FE, #8000FF);
}


.class-animation {
  display: inline-block;
  opacity: 0;
  transform: translateX(-10px);
  animation: slideIn .3s ease-in-out forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}


.skeleton-class {
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgb(247 251 254) 8%, rgb(223 237 248) 20%, rgb(247 251 254) 34%);
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}

@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

/* login poge css */
.login-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  background-color: #F4F9FD;
  height: 100vh;
  width: 100%;
}

.login-left-part {
  background-color: #3F8BFF;
  width: 40%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 70px;
}

.login-left-part>img {
  width: 60%;
}

.login-right-part {
  background-color: rgb(255, 255, 255);
  width: 60%;
  height: 100%;
  border-radius: 0px 20px 20px 0px;
}

.login-left-logo {
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
  gap: 0px;
}

.login-left-logo>p {
  font-size: 12px;
  color: white;
  padding: 5px 0px
}